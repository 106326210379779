import { Analytics, Auth } from 'aws-amplify'
import { AnalyticsEvent, AnalyticsLogger } from './analytics'
import { splitLongParams } from './splitLongParams'

const authConfig = {
  Auth: {
    identityPoolId: 'us-east-1:d4970302-be06-41a2-b01d-4362e5a2cce5',
    region: 'us-east-1'
  }
}

Auth.configure(authConfig)

const pinpointConfig = {
  AWSPinpoint: {
    appId: 'ef5deefacb4845c1b446dc7ae22627aa',
    region: 'us-east-1',
    mandatorySignIn: false
  }
}

Analytics.configure(pinpointConfig)

export const pinpointLogger: AnalyticsLogger = (event: AnalyticsEvent) => {
  Analytics.record({
    name: event.name,
    attributes: splitLongParams(event.params)
  })
}
