import { Accounts, PlanDetails, SubscriptionError, ReedemError } from './Accounts'
import { Result, Success } from 'services/results'

export const accounts: Accounts = {
  async getPlanDetails(_country: string, _plans: string[]): Promise<Result<PlanDetails, string>> {
    return Success({
      id: 'plan-id',
      price: 9999,
      currency: 'usd',
      duration: 12
    })
  },
  async getPlanDetailsByCurrency(currency: string, _plans: string[]): Promise<Result<PlanDetails, string>> {
    return Success({
      id: 'plan-id',
      price: 9999,
      currency: 'usd',
      duration: 12
    })
  },
  async createSubscription(
    _paymentMethodId: string,
    _email: string,
    _plan: string,
    _isTest?: boolean
  ): Promise<Result<undefined, SubscriptionError>> {
    return Success(undefined)
  },
  async getUserCurrencyFromStripe(_email: string): Promise<Result<string, string>> {
    return Success('gbp')
  },
  async reedemRockJamCoupon(email: string): Promise<Result<undefined, ReedemError>> {
    return Success(undefined)
  },
  async reedemVipGiftCoupon(email: string): Promise<Result<undefined, ReedemError>> {
    return Success(undefined)
  },
  async redeemTunerOfferCoupon(email: string): Promise<Result<undefined, ReedemError>> {
    return Success(undefined)
  },
  async redeemWomUniOfferCoupon(email: string, onemonth: boolean): Promise<Result<undefined, ReedemError>> {
    return Success(undefined)
  }
}
