import styled from 'styled-components'

export const ErrorContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
export const ImageContainer = styled.div`
  margin-top: 10em;
  text-align: center;
`

export const Title = styled.div`
  text-align: center;
  font-size: 44px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #29004e;
`

export const Message = styled.div`
  margin-top: 1em;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #29004e;
`

export const ButtonContainer = styled.div`
  margin-top: 2em;
  text-align: center;
`

export const Button = styled.button`
  width: 200px;
  height: 60px;
  border-radius: 30px;
  border: 0;
  background-color: #00ce77;
  font-size: 19px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`

export const Background = styled.div`
  position: fixed;
  left: 0vw;
  right: 0vw;
  top: 0vh;
  bottom: 0vh;
  background-color: white;
`
