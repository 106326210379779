import styled from 'styled-components'
export const borderColor = ({ error }: ErrorProps): string => (error ? '#fa755a' : '#e1e1e1')

export const TunerOfferBackground = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #140037;
  background-size: cover;
`

export const Container = styled.div`
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LogoContainer = styled.div`
  text-align: center;
`
export const Logo = styled.img`
  max-width: 100%;
  height: auto;
`

export const TunerOfferTitle = styled.div`
  padding-left: 2em;
  padding-right: 2em;
  font-family: byrdsimply, sans-serif;
  font-size: 2.2rem;
  font-weight: 900;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #f4f0dd;
  margin-bottom: 0.5em;
  @media (max-width: 768px) {
    padding-left: 1em;
    padding-right: 1em;
  }
`

export const TunerOfferSubtitle = styled.div`
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  color: #f4f0dd;
  @media (max-width: 768px) {
    padding-left: 1em;
    padding-right: 1em;
  }
`

interface ErrorProps {
  error: boolean
}
export const InputContainer = styled.div<ErrorProps>`
  color: #24004a;
  border-radius: 3px;
  border-radius: 3px;
  text-align: left;
  background-color: #ffffff;
  border: solid 1px ${borderColor};
  height: 70px;
  width: 500px;
  margin-left: 1em;
  margin-right: 1em;
  @media (max-width: 768px) {
    width: 80%;
  }
`
export const Input = styled.input`
  width: 80%;
  height: 100%;
  overflow: hidden;
  padding-left: 22px;
  font-size: 1.1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #24004a;
  border: none;
  background-color: #00000000;
`

export const FieldError = styled.div`
  width: 500px;
  text-align: left;
  white-space: nowrap;
  padding-left: 22px;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ff6767;
`

interface ButtonContainerProps {
  visible: boolean
}
export const ButtonContainer = styled.div<ButtonContainerProps>`
  margin-top: 2em;
  text-align: center;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`
export const Button = styled.button`
  width: 300px;
  height: 55px;
  border-radius: 24px;
  border: 0;
  background-color: #f3f0df;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: normal;
  text-align: center;
  color: #8217ff;
  cursor: pointer;
`

export const StoreContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Store = styled.button`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #f3f0df;
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  width: 240px;
  color: #3a2769;
  margin: 7px 10px;
  font-size: 13px;
  display: inline-block;
  padding: 15px 25px;
  text-align: left;
`
export const StoreLogo = styled.img`
  display: inline-block;
  max-width: 100%;
  margin-right: 15px;
`
export const StoreButtonContainer = styled.div`
  text-align: left;
  display: inline-block;
`
export const StoreButtonHeader = styled.div`
  /* margin-top: -13px; */
  font-size: 22px;
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`

export const StoreButtonText = styled.div`
  margin: 0px;
  /* margin-bottom: 7px; */
`
