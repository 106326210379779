import React, { FC } from 'react'
import { Glass } from './Glass'
import { Spinner } from './Spinner'

interface LoadingProps {
  loading: boolean
  darken?: boolean
}

export const Loading: FC<LoadingProps> = ({ loading, children, darken = false }) =>
  loading ? (
    <Glass darken={darken}>
      <Spinner />
    </Glass>
  ) : (
    <>{children}</>
  )
