import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { StripeElementChangeEvent } from '@stripe/stripe-js'
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'
import { once } from 'lodash/fp'
import { Image } from 'ui/Image'
import { cardCvcOptions, cardExpiryOptions, cardNumberOptions } from './StripeStyles'
import { useTimer } from 'util/useTimer'
import { formatDiscountSaving, formatPrice, formatSubtotal, isEmailValid } from 'util/utils'
import { SwitchLanguage } from 'services/localization/SwitchLanguage'
import { useLanguage, useTranslation } from 'services/localization/TranslationProvider'
import { businessEvent, clickEvent } from 'services/analytics/analytics'
import { useAnalytics } from 'services/analytics/AnalyticsProvider'
import {
  CardElementContainer,
  CardExpirationIconContainer,
  CardHolderNameIconContainer,
  CardNumberIconContainer,
  CardSecurityCodeIconContainer,
  Cell,
  Divider,
  EmailIconContainer,
  FieldError,
  Input,
  InputContainer,
  NotYou,
  PaymentFormCell,
  SavePrice, SaveDiv,
  Secured,
  StripeInputContainer,
  SubscribeButton,
  Subtotal,
  SubtotalPrice,
  Terms,
  Text,
  Title,
  Total,
  TotalPrice
} from './StyledComponents'
import info from '../images/sg-info.png'
import check from '../images/sg-check.png'
import alert from '../images/sg-alert-triangle.png'
import lock from '../images/sg-lock-icon.png'

interface ValidationIconProps {
  visible: boolean
  valid: boolean
  container: any
}
const ValidationIcon: FC<ValidationIconProps> = ({ visible, valid, container: Container }) =>
  visible ? (
    <Container>
      {valid && <Image src={check} width="24px" alt="check" style={{ paddingRight: '10px' }} />}
      {!valid && <Image src={alert} width="24px" alt="alert" style={{ paddingRight: '10px' }} />}
    </Container>
  ) : null


interface PaymentFormViewProps {
  email: string
  price: number
  currency: string
  planDuration: number
  discount: number
  subscribe(cardHolderName: string, email: string): void
}

export const PaymentFormView: FC<PaymentFormViewProps> = ({
  email: initialEmail,
  price,
  currency,
  planDuration,
  discount,
  subscribe
}) => {
  const texts = useTranslation().subscribe.purchaseForm;

  const emailRef = useRef<HTMLInputElement>(null)
  const logAnalyticsEvent = useAnalytics()
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [email, setEmail] = useState(initialEmail)
  const [collectEmail, setCollectEmail] = useState(initialEmail === '')
  const [cardHolderName, setCardHolderName] = useState('')
  const [cardNumberComplete, setCardNumberComplete] = useState(false)
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false)
  const [cardSecurityComplete, setCardSecurityComplete] = useState(false)
  const [cardSecurityEmpty, setCardSecurityEmpty] = useState(true)
  const [language] = useLanguage()


  useLayoutEffect(() => {
    if (collectEmail) {
      emailRef.current?.focus()
    }
  }, [collectEmail])

  const enableEmailCollection = async () => {
    setEmail('')
    setCollectEmail(true)
    logAnalyticsEvent(clickEvent('not-you'))
  }

  const emailValid = isEmailValid(email)
  const cardHolderNameValid = cardHolderName !== ''

  const trySubscribe = () => {
    logAnalyticsEvent(clickEvent('subscribe'))

    if (emailValid && cardHolderNameValid && cardNumberComplete && cardExpiryComplete && cardSecurityComplete) {
      logAnalyticsEvent(businessEvent('subscribe', { email }))
      subscribe(cardHolderName, email)
    } else {
      setShowValidationErrors(true)
    }
  }

  const logFieldEvent = (
    fieldName: 'email' | 'card-holder-name' | 'card-number' | 'card-expiry' | 'card-security',
    status: 'change' | 'complete'
  ) => () => {
    logAnalyticsEvent(businessEvent(fieldName, { status }))
  }

  const logEmailChange = useCallback(once(logFieldEvent('email', 'change')), [])
  const logEmailComplete = useCallback(once(logFieldEvent('email', 'complete')), [])
  const logCardHolderNameChange = useCallback(once(logFieldEvent('card-holder-name', 'change')), [])
  const logCardHolderNameComplete = useCallback(once(logFieldEvent('card-holder-name', 'complete')), [])
  const logCardNumberChange = useCallback(once(logFieldEvent('card-number', 'change')), [])
  const logCardNumberComplete = useCallback(once(logFieldEvent('card-number', 'complete')), [])
  const logCardExpiryChange = useCallback(once(logFieldEvent('card-expiry', 'change')), [])
  const logCardExpiryComplete = useCallback(once(logFieldEvent('card-expiry', 'complete')), [])
  const logCardSecurityChange = useCallback(once(logFieldEvent('card-security', 'change')), [])
  const logCardSecurityComplete = useCallback(once(logFieldEvent('card-security', 'complete')), [])

  const stripeElementChanged = (event: StripeElementChangeEvent) => {
    switch (event.elementType) {
      case 'cardNumber':
        setCardNumberComplete(event.complete)
        logCardNumberChange()
        if (event.complete) {
          logCardNumberComplete()
        }
        break
      case 'cardExpiry':
        setCardExpiryComplete(event.complete)
        logCardExpiryChange()
        if (event.complete) {
          logCardExpiryComplete()
        }
        break
      case 'cardCvc':
        setCardSecurityComplete(event.complete)
        setCardSecurityEmpty(event.empty)
        logCardSecurityChange()
        if (event.complete) {
          logCardSecurityComplete()
        }
        break
    }
  }

  const updateCardHolderName = (name: string) => {
    setCardHolderName(name)
    logCardHolderNameChange()
    if (name !== '') {
      logCardHolderNameComplete()
    }
  }

  const updateEmail = (email: string) => {
    setEmail(email)
    logEmailChange()
    if (isEmailValid(email)) {
      logEmailComplete()
    }
  }

  const priceBeforeDiscount = price / (1 - discount)

  const [hideTooltip, cancelHideTooltip] = useTimer(5000, ReactTooltip.hide)

  return (
    <PaymentFormCell>
      <ReactTooltip
        event="click"
        globalEventOff="mousedown"
        multiline={true}
        afterShow={hideTooltip}
        afterHide={cancelHideTooltip}
      />
      <Cell name={'language'}>
        <SwitchLanguage />
      </Cell>

      <Cell name="title">
        <Title>{texts.title}</Title>
      </Cell>

      <Cell name="email">
        <InputContainer error={showValidationErrors && !emailValid}>
          <Input
            data-testid="email-input"
            autoFocus
            ref={emailRef}
            disabled={!collectEmail}
            type="email"
            value={email}
            onChange={e => updateEmail(e.target.value)}
            placeholder={texts.email.placeholder}
          />
        </InputContainer>
      </Cell>
      {!collectEmail && (
        <NotYou onClick={enableEmailCollection}>{texts.notYou}</NotYou>
      )}      
      <ValidationIcon
        visible={collectEmail && showValidationErrors}
        valid={emailValid}
        container={EmailIconContainer}
      />
      <Cell name="emailError">
        {showValidationErrors && !emailValid && <FieldError>{texts.email.validationError}</FieldError>}
      </Cell>

      <Cell name="name">
        <InputContainer error={showValidationErrors && !cardHolderNameValid}>
          <Input
            data-testid="card-holder-name-input"
            type="text"
            placeholder={texts.name.placeholder}
            value={cardHolderName}
            onChange={e => updateCardHolderName(e.target.value)}
          />
        </InputContainer>
      </Cell>
      <ValidationIcon
        visible={showValidationErrors}
        valid={cardHolderNameValid}
        container={CardHolderNameIconContainer}
      />
      <Cell name="nameError">
        {showValidationErrors && !cardHolderNameValid && <FieldError>{texts.name.validationError}</FieldError>}
      </Cell>

      <Cell name="card">
        <CardElementContainer error={showValidationErrors && !cardNumberComplete}>
          <StripeInputContainer>
            <CardNumberElement options={cardNumberOptions} onChange={stripeElementChanged} />
          </StripeInputContainer>
        </CardElementContainer>
      </Cell>
      <ValidationIcon visible={showValidationErrors} valid={cardNumberComplete} container={CardNumberIconContainer} />
      <Cell name="cardError">
        {showValidationErrors && !cardNumberComplete && (
          <FieldError>{texts.cardNumber.validationError}</FieldError>
        )}
      </Cell>

      <Cell name="exp">
        <CardElementContainer error={showValidationErrors && !cardExpiryComplete}>
          <StripeInputContainer>
            <CardExpiryElement options={cardExpiryOptions} onChange={stripeElementChanged} />
          </StripeInputContainer>
        </CardElementContainer>
      </Cell>
      <ValidationIcon
        visible={showValidationErrors}
        valid={cardExpiryComplete}
        container={CardExpirationIconContainer}
      />
      <Cell name="expError">
        {showValidationErrors && !cardExpiryComplete && (
          <FieldError>{texts.expirationDate.validationError}</FieldError>
        )}
      </Cell>

      <Cell name="cvv">
        <CardElementContainer error={showValidationErrors && !cardSecurityComplete}>
          <StripeInputContainer>
            <CardCvcElement options={cardCvcOptions} onChange={stripeElementChanged} />
          </StripeInputContainer>
        </CardElementContainer>
      </Cell>
      <CardSecurityCodeIconContainer link={true}>
        <Image
          src={info}
          width="24px"
          alt="CVC"
          data-tip={texts.cardSecurityCodeHint}
          style={{ visibility: cardSecurityEmpty ? 'visible' : 'hidden' }}
        />
      </CardSecurityCodeIconContainer>
      <ValidationIcon
        visible={!cardSecurityEmpty && showValidationErrors}
        valid={cardSecurityComplete}
        container={CardSecurityCodeIconContainer}
      />
      <Cell name="cvvError">
        {showValidationErrors && !cardSecurityComplete && (
          <FieldError>{texts.securityCode.validationError}</FieldError>
        )}
      </Cell>

      {discount > 0 && (
        <>
          <Cell name="subtotal">
            <Subtotal align="left">
              {formatSubtotal(planDuration, priceBeforeDiscount, currency, language, texts.monthlySubtotal)}
            </Subtotal>
          </Cell>
          <Cell name="subtotalValue">
            <SubtotalPrice align="right">{formatPrice(priceBeforeDiscount, currency, language)}</SubtotalPrice>
          </Cell>

          <Cell name="save">
            <SaveDiv align="left">{formatDiscountSaving(discount * 100, texts.discountSaving)}</SaveDiv>
          </Cell>

          <Cell name="saveValue">
            <SavePrice align="right">{`${formatPrice(-priceBeforeDiscount * discount, currency, language)}`}</SavePrice>
          </Cell> 
        </>
      )}

      <Cell name="line">
        <Divider />
      </Cell>
      
      <Cell name="total">
        <Total align="left">{texts.total}</Total>
      </Cell>
      <Cell name="totalValue">
        <TotalPrice align="right">{formatPrice(price, currency, language)}</TotalPrice>
      </Cell>

      <Cell name="button">
        <SubscribeButton data-testid="subscribe-button" onClick={trySubscribe}>
          {texts.purchaseButton}
        </SubscribeButton>
      </Cell>

      <Cell name="secured">
        <Secured>
          {texts.billedAnnually}{' '}
          <Image
            alt="lock"
            src={lock}
            width="11px"
            style={{ marginLeft: '8px', marginRight: '8px', verticalAlign: 'middle' }}
          />{' '}
          {texts.securePayment}
        </Secured>
      </Cell>

      <Cell name="terms">
        <Terms href="https://www.joytunes.com/terms" target="_blank">
          {texts.terms.termsOfUse}
        </Terms>
        <Text> {texts.terms.and} </Text>
        <Terms href="https://www.joytunes.com/privacy" target="_blank">
          {texts.terms.privacyPolicy}
        </Terms>
      </Cell>
    </PaymentFormCell>
  )
}
