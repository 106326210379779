import React, { FC } from 'react'
import { useTranslation } from 'services/localization/TranslationProvider'
import {
  ImageContainer,
  BottomPitchCell,
  Press,
  TrustRibbons,
  SmallDescription
} from './StyledComponents';
import press from '../images/sg-press.png'
import trustRibbons from '../images/sg-trust-ribbons.png'

export const BottomPitch: FC = () => {
  const translation = useTranslation().subscribe.bottomPitch

  return (  
  <BottomPitchCell>
    <SmallDescription>{translation.trust1}<br/>{translation.trust2}</SmallDescription>
    <ImageContainer>
      <Press width="300px" alt="Press" src={press} />
    </ImageContainer>
    <ImageContainer>
      <TrustRibbons width="300px" alt="Trust Ribbons" src={trustRibbons} />
    </ImageContainer>
    <p />
  </BottomPitchCell>
  );
}
