import { PaymentMethod, Stripe, StripeCardNumberElement } from '@stripe/stripe-js'
import { Failure, Result, Success } from '../results'

const defaultError = 'Error in stripe.createPaymentMethod'

export const createPaymentMethod = async (
  stripe: Stripe,
  card: StripeCardNumberElement
): Promise<Result<PaymentMethod, string>> => {
  try {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card
    })

    if (paymentMethod) {
      return Success(paymentMethod!)
    } else if (error && error.message) {
      return Failure(error.message)
    } else {
      return Failure(defaultError)
    }
  } catch (e) {
    return Failure(defaultError)
  }
}
