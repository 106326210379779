import React, { FC } from 'react'
import { compile } from 'handlebars'
// import { DesktopOnly } from 'ui/DesktopOnly'
import { useTranslation } from 'services/localization/TranslationProvider'
import sgLogo from '../images/sg-logo.png'
import promo25Off from '../images/sg-25off-promo.png'
import stars from '../images/sg-stars.png'

import {
  ImageContainer,
  TopPitchCell,
  TopPitchTitle,
  Description,
  Testimony,
  TestimonyBody,
  TestimonyAuthor,
  Logo,
  Promo25Off,
  Stars
} from './StyledComponents'


interface TopPitchProps {
  duration: number
  discount: number
}

export const TopPitch: FC<TopPitchProps> = ({ duration, discount }) => {
  const translation = useTranslation().subscribe.topPitch
  
  return (
    <TopPitchCell>
      <ImageContainer>
        <Logo alt="Simply Guitar" width='280px' src={sgLogo} />
      </ImageContainer>
      <ImageContainer>
        <Promo25Off alt="25% Off" src={promo25Off} />
      </ImageContainer>
        
      <TopPitchTitle>{translation.title1}<br/>{compile(translation.title2)({ discount })}</TopPitchTitle>
      <Description>{compile(translation.duration)({ months: duration })}</Description>

      {/* <DesktopOnly> */}
        <Testimony>
          <TestimonyBody>{translation.testimonial.quote}</TestimonyBody>
          <br/>
          <TestimonyAuthor>{translation.testimonial.author}</TestimonyAuthor>
        </Testimony>
        <ImageContainer>
          <Stars width="120px" alt="Stars" src={stars} />
        </ImageContainer>
      {/* </DesktopOnly> */}
    </TopPitchCell>
  )
}
