import React, { FC } from 'react'
import { compile } from 'handlebars'
import { RouteComponentProps } from '@reach/router'
import {
  WelcomeBackground,
  Container,
  LogoContainer,
  Logo,
  WelcomeTitle,
  WelcomeSubtitle,
  WelcomeDescription,
  Button,
  ButtonContainer,
  WelcomeFooter,
} from './components/StyledComponents'
import { useTranslation } from 'services/localization/TranslationProvider'
import { parseReferralQueryParams } from 'util/referralQueryParams'
import { appFlyerDownloadLink } from '../services/accounts/AppFlyerDownloadLink'
import { useAnalytics, useViewAnalyticsEvent } from 'services/analytics/AnalyticsProvider'
import { clickEvent } from 'services/analytics/analytics'
import sgLogo from './images/sg-logo.png'

interface WelcomeProps extends RouteComponentProps {
  email: string
}

export const Welcome: FC<WelcomeProps> = (props: WelcomeProps) => {
  const translation = useTranslation().subscribe.welcomeScreen

  const welcomePageName = 'welcome-page'
  useViewAnalyticsEvent(welcomePageName)

  const email = props.email 
  const logAnalyticsEvent = useAnalytics()
  let queryParamsString = ''
  if (typeof props.location?.state === 'object' && props.location?.state !== null) {
    queryParamsString = (props.location.state as any).search
  }
  const referralQueryParams = parseReferralQueryParams(queryParamsString)

  const openSimplyGuitar = () => {
    logAnalyticsEvent(clickEvent('open-simply-guitar'))
    window.location.href = appFlyerDownloadLink(referralQueryParams)
  }

  return (
    <WelcomeBackground>
      <Container>
        <LogoContainer style={{ textAlign: 'center' }}>
          <Logo alt="Simply Guitar" width='315px' src={sgLogo} />
        </LogoContainer>
        <WelcomeTitle>{translation.title}</WelcomeTitle>
        <WelcomeSubtitle>{translation.subtitle.first}</WelcomeSubtitle>
        <WelcomeSubtitle>{translation.subtitle.second}</WelcomeSubtitle>
        <WelcomeDescription>{compile(translation.description)({ email })}</WelcomeDescription>
        <ButtonContainer visible={true}>
          <Button onClick={openSimplyGuitar}>{translation.button}</Button>
        </ButtonContainer>
        <WelcomeFooter>{translation.footer}</WelcomeFooter>
        <p />
      </Container>
    </WelcomeBackground>
  )
}
