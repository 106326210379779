import React, { FC, createContext, useContext, useState } from 'react'
import { parse as parseQueryString } from 'query-string'
import { getTranslation, Translation } from './translations'
import { Language, isLanguage } from './languages'

interface Localization {
  language: Language
  setLanguage(language: Language): void
  translation: Translation
}

export const TranslationContext = createContext<Localization>(null as any)

export const TranslationProvider: FC = ({ children }) => {
  const location = window.location

  const languageCode = (location && parseQueryString(location.search).locale) ?? ''

  const [language, setLanguage] = useState<Language>(isLanguage(languageCode) ? languageCode : 'en')

  return (
    <TranslationContext.Provider value={{ language, setLanguage, translation: getTranslation(language) }}>
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslation: () => Translation = () => useContext(TranslationContext).translation

export const useLanguage: () => [Language, (language: Language) => void] = () => {
  const localization = useContext(TranslationContext)
  return [localization.language, localization.setLanguage]
}
