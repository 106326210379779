import React, { FC } from 'react'
import { parse as parseQueryString } from 'query-string'
import { isNull, isString } from 'lodash/fp'

const getDebugQueryParam = () => parseQueryString(window.location.search).debug

export const isDebugMode = (): boolean => {
  const debug = getDebugQueryParam()
  return isNull(debug) || isString(debug)
}

export const shouldShowDebugInfo = (): boolean => getDebugQueryParam() !== 'hide-debug-info'

interface DebugProps {
  info?: boolean
}

export const Debug: FC<DebugProps> = ({ children, info = false }) => {
  if (info) {
    return isDebugMode() && shouldShowDebugInfo() ? <>{children}</> : null
  } else {
    return isDebugMode() ? <>{children}</> : null
  }
}
