import { accounts as mockAccounts } from './MockAccounts'
import { accounts as productionAccounts } from './ProductionAccounts'
import { Result } from 'services/results'

export interface PlanDetails {
  id: string
  price: number
  currency: string
  duration: number
}

export interface SubscriptionError {
  errorCode: number
  errorString: string
}

export interface ReedemError {
  errorCode: number
  errorString: string
}

export interface IRegisterAccountRequest {
  email: string
  deviceID: string
  sessionID: string
}

export interface IRegisterAccountResponse {
  accountID: string
}

export interface Accounts {
  getPlanDetails(country: string, plans: string[]): Promise<Result<PlanDetails, string>>
  getPlanDetailsByCurrency(currency: string, plans: string[]): Promise<Result<PlanDetails, string>>
  createSubscription(
    paymentMethodId: string,
    email: string,
    plan: string,
    isTest?: boolean
  ): Promise<Result<undefined, SubscriptionError>>
  getUserCurrencyFromStripe(email: string): Promise<Result<string, string>>
  reedemRockJamCoupon(email: string): Promise<Result<undefined, ReedemError>>
  redeemTunerOfferCoupon(email: string): Promise<Result<undefined, ReedemError>>
  reedemVipGiftCoupon(email: string): Promise<Result<undefined, ReedemError>>
  redeemWomUniOfferCoupon(email: string, onemonth: Boolean): Promise<Result<undefined, ReedemError>>
}

const mockAsla = process.env.REACT_APP_MOCK_ASLA === 'true'

export const accounts: Accounts = mockAsla ? mockAccounts : productionAccounts
