import React, { FC } from 'react'
import { compile } from 'handlebars'
import { RouteComponentProps } from '@reach/router'
import {
  Container,
  LogoContainer,
  Logo,
  StoreContainer,
  Store,
  StoreLogo,
  StoreButtonContainer,
  StoreButtonHeader,
  StoreButtonText,
  TunerOfferBackground,
  TunerOfferTitle,
  TunerOfferSubtitle
} from './components/StyledComponents'
import { useTranslation } from 'services/localization/TranslationProvider'
import { parseReferralQueryParams } from 'util/referralQueryParams'
import { appFlyerDownloadLinkPlayStore } from '../services/accounts/AppFlyerDownloadLink'
import { useAnalytics, useViewAnalyticsEvent } from 'services/analytics/AnalyticsProvider'
import { clickEvent } from 'services/analytics/analytics'
import sgLogo from './images/sg-logo.png'
import appleLogo from './images/apple.svg'
import playLogo from './images/play.svg'

interface CouponAppliedProps extends RouteComponentProps {
  email: string
}

export const TunerCouponApplied: FC<CouponAppliedProps> = (props: CouponAppliedProps) => {
  const translation = useTranslation().tunerCouponApplied

  useViewAnalyticsEvent('coupon-applied-page')

  const email = props.email
  const logAnalyticsEvent = useAnalytics()
  let queryParamsString = ''
  if (typeof props.location?.state === 'object' && props.location?.state !== null) {
    queryParamsString = (props.location.state as any).search
  }
  const referralQueryParams = parseReferralQueryParams(queryParamsString)

  const openAppStore = () => {
    logAnalyticsEvent(clickEvent('open-appstore'))
    window.location.href = 'https://apps.apple.com/US/app/id1476695335'
  }

  const openPlayStore = () => {
    logAnalyticsEvent(clickEvent('open-playstore'))
    window.location.href = appFlyerDownloadLinkPlayStore(referralQueryParams)
  }

  return (
    <TunerOfferBackground>
      <Container>
        <LogoContainer>
          <Logo alt="Simply Guitar" width="315px" src={sgLogo} />
        </LogoContainer>

        <TunerOfferTitle>{translation.title}</TunerOfferTitle>
        <TunerOfferSubtitle>{compile(translation.subtitle)({ email })}</TunerOfferSubtitle>
        <p />

        <StoreContainer>
          <Store onClick={openAppStore}>
            <StoreLogo src={appleLogo} />
            <StoreButtonContainer>
              <StoreButtonText>Download on the </StoreButtonText>
              <StoreButtonHeader>App Store</StoreButtonHeader>
            </StoreButtonContainer>
          </Store>
          <Store onClick={openPlayStore}>
            <StoreLogo src={playLogo} />
            <StoreButtonContainer>
              <StoreButtonText>Get it on </StoreButtonText>
              <StoreButtonHeader>Google Play</StoreButtonHeader>
            </StoreButtonContainer>
          </Store>
        </StoreContainer>

        <p />
      </Container>
    </TunerOfferBackground>
  )
}
