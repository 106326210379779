import React, { FC, useRef, useState } from 'react'
import { Language, languages } from './languages'
import { range, map, zip, forEach } from 'lodash/fp'
import styled from 'styled-components'

const SweepButton = styled.button`
  position: fixed;
  top: 1em;
  right: 1em;
`

const delayDuration = 4000 // milliseconds

interface SweepLanguagesProps {
  setLanguage(language: Language): void
}

export const SweepLanguages: FC<SweepLanguagesProps> = ({ setLanguage }) => {
  const [sweeping, setSweeping] = useState(false)
  const timers = useRef<number[]>([])

  const sweep = () => {
    timers.current = []
    const delays = map(i => i * delayDuration, range(0, languages.length))

    forEach(([language, delay]) => {
      timers.current.push(setTimeout(() => setLanguage(language), delay))
    }, zip(languages, delays))

    timers.current.push(setTimeout(stop, delayDuration * languages.length))
    setSweeping(true)
  }

  const stop = () => {
    forEach(timer => {
      clearTimeout(timer)
    }, timers.current)
    timers.current = []
    setSweeping(false)
  }

  return <SweepButton onClick={sweeping ? stop : sweep}>{sweeping ? 'Stop sweeping' : 'Sweep languages'}</SweepButton>
}
