import styled from 'styled-components'
import { Image } from 'ui/Image'
import pitchBackground from '../images/sg-pitch-background.png'
import welcomeBackground from '../images/sg-welcome-background.png'
export const borderColor = ({ error }: ErrorProps): string => (error ? '#fa755a' : '#e1e1e1')

interface ImageContainerProps {
  marginBottom?: string
  marginTop?: string
}
export const ImageContainer = styled.div<ImageContainerProps>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  @media (max-width: 768px) {
    text-align: center;
  }
`
export const TopPitchCell = styled.div`
  grid-area: topPitch;
  text-align: center;
  padding-top: 30px;
  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: 768px) {
    background-image: url(${pitchBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const TopPitchTitle = styled.h1`
  font-weight: 700;
  font-size: 2.7em;
  margin: 0;
  color: #ffffff;
  line-height: 1em;
  padding-bottom: 0.5em;

  @media (max-width: 768px) {
    font-size: 3em;
    text-align: center;
  }
`
export const Description = styled.div`
  font-weight: 700;
  font-size: 1.2em;
  color: #ffffff;
  @media (max-width: 768px) {
    text-align: center;
  }
`
export const Testimony = styled.p`
  margin-top: 30px;
  right: 200px;
  font-size: 0.8rem;
  line-height: 2em;
  @media (max-width: 768px) {
    text-align: center;
  }
`
export const TestimonyBody = styled.span`
  font-weight: 400;
  margin-top: 30px;
  font-size: 1.2em;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
`
export const TestimonyAuthor = styled(TestimonyBody)`
  font-weight: 700;
`
export const Logo = styled.img`
  
`
export const Promo25Off = styled.img`
  margin-top: -15px;
  margin-bottom: -20px;
`

export const Stars = styled(Image)`
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }
`

export const BottomPitchCell = styled.div`
  padding-top: 40px;
  padding-left: 80px;
  padding-right: 80px;
  grid-area: bottomPitch;
  text-align: center;

  @media (max-width: 768px) {
    background-image: url(${pitchBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
`
export const SmallDescription = styled.div`
  font-weight: 700;
  font-size: 0.8em;
  color: #ffffff;
  @media (max-width: 768px) {
    text-align: center;
  }
  margin-bottom: 10px;
`
export const Press = styled(Image)`
  margin-bottom: 20px;
`
export const TrustRibbons = styled(Image)``



export const PaymentGrid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 40vw 60vw;
  grid-template-rows: auto auto;
  grid-template-areas:
    'topPitch     payment'
    'bottomPitch  payment';

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'topPitch'
      'payment'
      'bottomPitch';
  }
`
export const PitchBackground = styled.div`
  grid-column: 1;
  grid-row: 1 / 3;
  z-index: -1;
  background-image: url(${pitchBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media (max-width: 768px) {
    visibility: hidden;
  }
`

export const PaymentFormCell = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: 13fr 10fr 2fr 40px 3fr 10fr 2fr 40px 13fr;
  grid-template-rows: 100px auto 50px 55px 12px 55px 12px 55px 12px 55px 20px 25px 25px 30px 40px 70px 40px 100px;
  grid-template-areas:
    'lm   top         top         top         top         top           top           top           rm'
    'lm   language    language    language    language    language      language      language      rm'
    'lm   title       title       title       title       title         title         title         rm'
    'lm   email       email       email       email       email         email         email         rm'
    'lm   emailError  emailError  emailError  emailError  emailError    emailError    emailError    rm'
    'lm   name        name        name        name        name          name          name          rm'
    'lm   nameError   nameError   nameError   nameError   nameError     nameError     nameError     rm'
    'lm   card        card        card        card        card          card          card          rm'
    'lm   cardError   cardError   cardError   cardError   cardError     cardError     cardError     rm'
    'lm   exp         exp         exp         gap         cvv           cvv           cvv           rm'
    'lm   expError    expError    expError    gap         cvvError      cvvError      cvvError      rm'
    'lm   subtotal    subtotal    subtotal    subtotal    subtotalValue subtotalValue subtotalValue rm'
    'lm   save        save        save        save        saveValue     saveValue     saveValue     rm'
    'lm   line        line        line        line        line          line          line          rm'
    'lm   total       total       total       totalValue  totalValue    totalValue    totalValue    rm'
    'lm   button      button      button      button      button        button        button        rm'
    'lm   secured     secured     secured     secured     secured       secured       secured       rm'
    'lm   childSafe   childSafe   childSafe   childSafe   childSafe     childSafe     childSafe     rm'
    'lm   terms       terms       terms       terms       terms         terms         terms         rm';

  grid-area: payment;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 10fr 6fr 20px 40px 1fr;
    grid-template-rows: 40px 0px auto 55px 12px 55px 12px 55px 12px 55px 12px 55px 20px 25px 25px 30px 40px 70px 30px 50px;
    grid-template-areas:
      'lm top          top            top            top'
      'lm title        title          title          title'
      'lm language     language       language       language'
      'lm email        email          email          email'
      'lm emailError   emailError     emailError     emailError'
      'lm name         name           name           name'
      'lm nameError    nameError      nameError      nameError'
      'lm card         card           card           card'
      'lm cardError    cardError      cardError      cardError'
      'lm exp          exp            exp            exp'
      'lm expError     expError       expError       expError'
      'lm cvv          cvv            cvv            cvv'
      'lm cvvError     cvvError       cvvError       cvvError'
      'lm subtotal     subtotalValue  subtotalValue  subtotalValue'
      'lm save         saveValue      saveValue      saveValue'
      'lm line         line           line           line'
      'lm total        totalValue     totalValue     totalValue'
      'lm button       button         button         button'
      'lm secured      secured        secured        secured'
      'lm childSafe    childSafe      childSafe      childSafe'
      'lm terms        terms          terms          terms';
  }
`

export const EmailFormCell = styled.div`
  margin-right: 15px;
  display: grid;
  gap: 4px;
  grid-template-columns: 13fr 10fr 2fr 40px 3fr 10fr 2fr 40px 13fr;
  grid-template-rows: 100px 100px 55px 12px 55px 100px;
  grid-template-areas:
    'lm   top         top         top         top         top           top           top           rm'
    'lm   title       title       title       title       title         title         title         rm'
    'lm   email       email       email       email       email         email         email         rm'
    'lm   emailError  emailError  emailError  emailError  emailError    emailError    emailError    rm'
    'lm   button      button      button      button      button        button        button        rm';

  grid-area: payment;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 10fr 6fr 20px 40px 1fr;
    grid-template-rows: 40px 40px 55px 12px 55px 50px;
    grid-template-areas:
      'lm   top         top         top         top         top           top           top           rm'
      'lm   title       title       title       title       title         title         title         rm'
      'lm   email       email       email       email       email         email         email         rm'
      'lm   emailError  emailError  emailError  emailError  emailError    emailError    emailError    rm'
      'lm   button      button      button      button      button        button        button        rm';
  }
`

export const Title = styled.span`
  width: 100%;
  grid-area: title;
  font-size: min(1.8em, 2.5vw);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  color: #7a00ff;
  @media (max-width: 768px) {
    display: none;
  }
`

export const SmallTitle = styled.span`
  width: 100%;
  grid-area: title;
  font-size: 1.5em;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  color: #000000;
`

export const FieldError = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  white-space: nowrap;
  padding-left: 22px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ff6767;
`

interface Alignment {
  align: 'left' | 'right' | 'center'
}

export const Subtotal = styled.div<Alignment>`
  font-size: 0.9em;
  font-weight: 900;
  font-stretch: normal;
  font-style: strike;
  line-height: 1;
  letter-spacing: normal;
  padding-left: 22px;
  color: #bababa;
  white-space: nowrap;
  text-align: ${({ align }) => align};
`

export const SaveDiv = styled.div<Alignment>`
  font-size: 0.9em;
  font-weight: 900;
  font-stretch: normal;
  font-style: strike;
  line-height: 1;
  letter-spacing: normal;
  padding-left: 22px;
  color: #7a00ff;
  white-space: nowrap;
  text-align: ${({ align }) => align};
`

export const SavePrice = styled(Subtotal)`
  padding-right: 22px;
  color: #7a00ff;
`

export const SubtotalPrice = styled(SavePrice)`
  text-decoration-line: line-through;
  color: #bababa;`

export const Total = styled.div<Alignment>`
  font-size: 1em;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding-left: 22px;
  color: #24004a;
  text-align: ${({ align }) => align};
`

export const TotalPrice = styled(Total)`
  padding-right: 22px;
`

export const Divider = styled.hr`
  margin-left: 22px;
  margin-right: 22px;
  border: 1px solid #e1e1e1;
`

export const SubscribeButton = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  border: 0;
  white-space: nowrap;
  background-color: #00ce77;
  font-size: min(1.4em, 2vw);
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 1em;
  }
`

export const Secured = styled.div`
  width: 100%;
  margin-top: 16px;
  font-size: 0.8em;
  color: #2e0051;
`

export const Terms = styled.a`
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #340055;
  opacity: 0.5;
`

export const Text = styled.span`
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #340055;
  opacity: 0.5;
`

interface ErrorProps {
  error: boolean
}

export const StripeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  height: 100%;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
`

export const InputContainer = styled.div<ErrorProps>`
  width: 100%;
  height: 100%;
  color: #24004a;
  border-radius: 30px;
  border-radius: 30px;
  text-align: left;
  border: solid 1px ${borderColor};
`

export const Input = styled.input`
  width: 70%;
  height: 100%;
  overflow: hidden;
  padding-left: 22px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #24004a;
  border: none;
  background-color: #00000000;
  @media (max-width: 768px) {
    width: 70%;
  }
`

interface CellProps {
  name: string
}

export const Cell = styled.div<CellProps>`
  grid-area: ${({ name }) => name};
  text-align: center;
`

export const CardElementContainer = styled.div<ErrorProps>`
  width: 100%;
  height: 55px;
  border-radius: 30px;
  border: solid 1px ${borderColor};
`

export const NotYou = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 100%;
  height: 100%;
  grid-column: 7 / 9;
  grid-row: 4;
  line-height: 1fr;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  color: #017dff;
  cursor: pointer;
  white-space: nowrap;
  @media (max-width: 768px) {
    text-align: right;
    grid-column: 4;
  }
`

interface IconContainerProps {
  link?: boolean
}

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 50;
  cursor: ${({ link }) => (link ? 'pointer' : '')};
`

export const EmailIconContainer = styled(IconContainer)`
  grid-column: 8;
  grid-row: 4;

  @media (max-width: 768px) {
    grid-column: 5;
  }
`

export const CardHolderNameIconContainer = styled(IconContainer)`
  grid-column: 8;
  grid-row: 6;

  @media (max-width: 768px) {
    grid-column: 5;
  }
`

export const CardNumberIconContainer = styled(IconContainer)`
  grid-column: 8;
  grid-row: 8;

  @media (max-width: 768px) {
    grid-column: 5;
  }
`

export const CardExpirationIconContainer = styled(IconContainer)`
  grid-column: 3;
  grid-row: 10;

  @media (max-width: 768px) {
    grid-column: 5;
  }
`

export const CardSecurityCodeIconContainer = styled(IconContainer)`
  grid-column: 8;
  grid-row: 10;

  @media (max-width: 768px) {
    grid-column: 5;
    grid-row: 12;
  }
`


export const WelcomeBackground = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: url(${welcomeBackground}) no-repeat;
  background-size: cover;
`

export const Container = styled.div`
  height: 100vh;
  overflow: auto;
`

export const LogoContainer = styled.div`
  margin-top: 10em;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 4em;
  }
`

export const WelcomeTitle = styled.div`
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 1em;
  @media (max-width: 768px) {
    padding-left: 1em;
    padding-right: 1em;
  }
`

export const WelcomeSubtitle = styled.div`
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
`

export const WelcomeDescription = styled.div`
  margin-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1.2rem;
  font-weight: 900;
  text-align: center;
  color: #fff;
`

interface ButtonContainerProps {
  visible: boolean
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  margin-top: 2em;
  text-align: center;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

export const Button = styled.button`
  width: 300px;
  height: 70px;
  border-radius: 35px;
  border: 0;
  background-color: #00ce77;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`

export const WelcomeFooter = styled.div`
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
`
