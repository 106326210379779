import React, { FC } from 'react'
import { map } from 'lodash/fp'
import { languageNames, languages } from './languages'
import { useLanguage } from './TranslationProvider'
import { SwitchContainer } from './switchLanguageStyledComponents'
import { SweepLanguages } from './SweepLanguages'
import { Debug } from 'services/debug/Debug'
import Select from 'react-select'
import { find } from 'lodash/fp'
import { StylesConfig } from 'react-select/src/styles'

export const SwitchLanguage: FC = () => {
  const [language, setLanguage] = useLanguage()

  const options = map(language => ({ value: language, label: languageNames[language] }), languages)
  const defaultValue = find(o => o.value === language, options)

  const styles: StylesConfig = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'gray' : 'white'
    }),
    control: provided => ({
      ...provided,
      width: 200,
      marginBottom: '20px',
      cursor: 'pointer'
    })
  }

  return (
    <SwitchContainer>
      <Select
        styles={styles}
        options={options}
        value={defaultValue}
        isSearchable={false}
        onChange={e => setLanguage((e as any).value)}
      >
        {map(
          language => (
            <option key={language} value={language}>
              {languageNames[language]}
            </option>
          ),
          languages
        )}
      </Select>
      <Debug>
        <SweepLanguages setLanguage={setLanguage} />
      </Debug>
    </SwitchContainer>
  )
}
