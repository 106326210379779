import { v4 as uuidv4 } from 'uuid'

const DEVICE_ID_STORAGE_KEY = 'WEBFLOW_SESSION_ID'

export function getDeviceId() {
  let deviceId = localStorage.getItem(DEVICE_ID_STORAGE_KEY)

  if (!deviceId) {
    deviceId = uuidv4()
    localStorage.setItem(DEVICE_ID_STORAGE_KEY, deviceId)
  }

  return deviceId
}
