import React, { FC, useState } from 'react'
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useLocation, useNavigate } from '@reach/router'
import { accounts, PlanDetails } from '../../services/accounts/Accounts'
import { PaymentFormView } from './PaymentFormView'
import { Loading } from 'ui/Loading'
import { ErrorId } from 'ui/errors/errorPage/ErrorView'
import { ErrorDialog } from 'ui/errors/errorPage/ErrorDialog'
import { useLoading } from 'util/useLoading'
import { createPaymentMethod } from 'services/stripe/createPaymentMethod'
import { isFailure, isSuccess } from 'services/results'
import { useAnalytics, useViewAnalyticsEvent } from 'services/analytics/AnalyticsProvider'
import { apiCallEvent, apiCallReturnEvent, errorEvent } from 'services/analytics/analytics'

interface PaymentFormProps {
  email: string
  planDetails: PlanDetails
  discount: number
}

export const PaymentForm: FC<PaymentFormProps> = ({ email, planDetails, discount }) => {
  useViewAnalyticsEvent('payment-form')
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const location = useLocation()
  const logAnalyticsEvent = useAnalytics()
  const [loading, startLoading, stopLoading] = useLoading()
  const [errorId, setErrorId] = useState<ErrorId>()
  const subscribe = async (cardHolderName: string, email: string) => {
    startLoading()
    try {
      if (!stripe || !elements) {
        logAnalyticsEvent(errorEvent('Stripe is not defined'))
        setErrorId('payment')
        return
      }

      const cardNumberElement = elements.getElement(CardNumberElement)
      if (!cardNumberElement) {
        logAnalyticsEvent(errorEvent('Stripe card element is not defined'))
        setErrorId('payment')
        return
      }

      logAnalyticsEvent(apiCallEvent('stripe.createPaymentMethod', { plan_id: planDetails.id }))
      const paymentMethodResult = await createPaymentMethod(stripe, cardNumberElement)

      if (isFailure(paymentMethodResult)) {
        logAnalyticsEvent(errorEvent(`Error in stripe.createPaymentMethod ${paymentMethodResult.error}`))
        setErrorId('payment')
      } else {
        logAnalyticsEvent(apiCallReturnEvent('stripe.createPaymentMethod'))

        const paymentMethodId = paymentMethodResult.value.id
        if (paymentMethodId) {
          logAnalyticsEvent(apiCallEvent('accounts.createSubscription', { email, plan_id: planDetails.id }))
          const result = await accounts.createSubscription(paymentMethodId, email, planDetails.id)
          if (isSuccess(result)) {
            stopLoading()
            logAnalyticsEvent(apiCallReturnEvent('accounts.createSubscription', planDetails.id))
            await navigate(`/welcome/${email}`, { state: { search: location.search } })
          } else {
            logAnalyticsEvent(
              errorEvent(`Error in accounts.createSubscription ${result.error.errorCode}: ${result.error.errorString}`)
            )
            if (result.error.errorCode === 403) {
              setErrorId('accountAlreadyExists')
            } else {
              setErrorId('payment')
            }
          }
        } else {
          setErrorId('payment')
        }
      }
    } finally {
      stopLoading()
    }
  }

  return (
    <>
      <ErrorDialog errorId={errorId} onClose={() => setErrorId(undefined)} />
      <Loading loading={loading} darken={true} />
      <PaymentFormView
        email={email}
        price={planDetails.price}
        currency={planDetails.currency}
        planDuration={planDetails.duration}
        discount={discount}
        subscribe={subscribe}
      />
    </>
  )
}
