import { assignWith, defaultTo } from 'lodash/fp'
import { parse as parseQueryString } from 'query-string'

export interface ReferralQueryParams {
  af_tranid?: string
  pid?: string
  af_referrer_customer_id?: string
  af_web_id?: string
  af_channel?: string
  af_siteid?: string
  af_referrer_name?: string
  c?: string
  af_referrer_uid?: string
}

export const parseReferralQueryParams = (searchString: string): ReferralQueryParams => {
  return assignWith(defaultTo, {} as ReferralQueryParams, parseQueryString(searchString))
}
