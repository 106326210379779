import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
  StripeElementStyle
} from '@stripe/stripe-js'

const cardElementStyle: StripeElementStyle = {
  base: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#24004a',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#bababa'
    }
  },
  invalid: {
    color: '#fa755a'
  }
}

export const cardNumberOptions: StripeCardNumberElementOptions = {
  style: cardElementStyle,
  showIcon: true
}

export const cardExpiryOptions: StripeCardExpiryElementOptions = {
  style: cardElementStyle
}

export const cardCvcOptions: StripeCardCvcElementOptions = {
  style: cardElementStyle
}
