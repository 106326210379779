import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import './index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { DebugInfo } from 'ui/DebugInfo'
import { Debug } from 'services/debug/Debug'

render(
  <StrictMode>
    <Debug info={true}>
      <DebugInfo />
    </Debug>
    <App />
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
