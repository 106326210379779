import React, { FC } from 'react'
import { compile } from 'handlebars'
import { RouteComponentProps } from '@reach/router'
import {
  GiftBackground,
  Container,
  LogoContainer,
  Logo,
  GiftTitle,
  GiftSubtitle,
  StoreContainer,
  Store,
  StoreLogo,
  StoreButtonContainer,
  StoreButtonHeader,
  StoreButtonText
} from './components/StyledComponents'
import { useTranslation } from 'services/localization/TranslationProvider'
import { parseReferralQueryParams } from 'util/referralQueryParams'
import { appFlyerDownloadLink, appFlyerDownloadLinkPlayStore } from '../services/accounts/AppFlyerDownloadLink'
import { useAnalytics, useViewAnalyticsEvent } from 'services/analytics/AnalyticsProvider'
import { clickEvent } from 'services/analytics/analytics'
import simplyLogos from './images/simply-logos.png'
import appleLogo from './images/apple.svg'
import playLogo from './images/play.svg'

interface VipGiftCouponAppliedProps extends RouteComponentProps {
  email: string
}

export const VipGiftCouponApplied: FC<VipGiftCouponAppliedProps> = (props: VipGiftCouponAppliedProps) => {
  const translation = useTranslation().giftApplied

  useViewAnalyticsEvent('gift-coupon-applied-page')

  const email = props.email
  const logAnalyticsEvent = useAnalytics()
  let queryParamsString = ''
  if (typeof props.location?.state === 'object' && props.location?.state !== null) {
    queryParamsString = (props.location.state as any).search
  }
  const referralQueryParams = parseReferralQueryParams(queryParamsString)

  const openAppStore = () => {
    logAnalyticsEvent(clickEvent('open-appstore'))
    window.location.href = appFlyerDownloadLink(referralQueryParams)
  }

  const openPlayStore = () => {
    logAnalyticsEvent(clickEvent('open-playstore'))
    window.location.href = appFlyerDownloadLinkPlayStore(referralQueryParams)
  }

  return (
    <GiftBackground>
      <Container>
        <LogoContainer>
          <Logo alt="Simply Guitar" width='600px' src={simplyLogos} />
        </LogoContainer>

        <GiftTitle>{translation.title}</GiftTitle>
        <GiftSubtitle>{compile(translation.subtitle)({ email })}</GiftSubtitle>
        <p />

        <StoreContainer>
          <Store onClick={openAppStore} >
            <StoreLogo src={appleLogo} />
            <StoreButtonContainer >
              <StoreButtonText>{translation.appleDownload}</StoreButtonText>
              <StoreButtonHeader>{translation.appleStoreName}</StoreButtonHeader>
            </StoreButtonContainer>
          </Store>
          <Store onClick={openPlayStore} >
            <StoreLogo src={playLogo} />
            <StoreButtonContainer>
              <StoreButtonText>{translation.googleDownload}</StoreButtonText>
              <StoreButtonHeader>{translation.googleStoreName}</StoreButtonHeader>
            </StoreButtonContainer>
          </Store>
        </StoreContainer>

        <p />
      </Container>
    </GiftBackground>
  )
}
