import { AnalyticsEventParams } from './analytics'

// TODO(elad): make type safe once template literal types are supported as keys. E.g:
//   type SplitParams = { [key: `${keyof AnalyticsEventParams}_${number}`]: string }
// (pending: https://github.com/microsoft/TypeScript/pull/26797)
export const splitLongParams = (params: AnalyticsEventParams) => {
  const eventParamsKeys = (Object.keys(params) as unknown) as (keyof AnalyticsEventParams)[]

  const splitParams = eventParamsKeys.reduce<{ [key: string]: any }>((result, key) => {
    const value = params[key]

    if (typeof value !== 'string' || value.length <= 200) {
      result[key] = value
    } else {
      const splitValues = value.match(/.{1,200}/g)

      let index = 1

      result[key] = splitValues!.shift()

      for (const currSplitValue of splitValues!) {
        result[`${key}_${index}`] = currSplitValue
        ++index
      }
    }

    return result
  }, {})

  return splitParams
}
