export function readUtmParams(): IUtmParams {
  const urlSearchParams = new URLSearchParams(window.location.search)

  const utmCampaign = urlSearchParams.get('utm_campaign')
  const utmMedium = urlSearchParams.get('utm_medium')
  const utmSource = urlSearchParams.get('utm_source')

  return Object.assign(
    utmCampaign ? { utmCampaign } : {},
    utmMedium ? { utmMedium } : {},
    utmSource ? { utmSource } : {}
  )
}
