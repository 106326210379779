export interface Success<T> {
  type: 'success'
  value: T
}

export function Success<T>(value: T): Success<T> {
  return { type: 'success', value }
}

export interface Failure<T> {
  type: 'failure'
  error: T
}

export const Failure = <T>(error: T): Failure<T> => ({ type: 'failure', error })

export type Result<S, F> = Success<S> | Failure<F>
export const isSuccess = <S, F>(result: Result<S, F>): result is Success<S> => result.type === 'success'
export const isFailure = <S, F>(result: Result<S, F>): result is Failure<F> => result.type === 'failure'
