import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import { noop, forEach, flow } from 'lodash/fp'
import { getSessionId } from '../../util/getSessionId'
import { pinpointLogger } from './pinpointLogger'
import { getDeviceId } from '../../util/getDeviceId'

// noinspection SpellCheckingInspection
const firebaseConfig = {
  apiKey: "AIzaSyCtjKd7j20O0xUj7_f5U-Lmk9m208yu0II",
  projectId: "simply-guitar-website",
  authDomain: "simply-guitar-website.firebaseapp.com",
  storageBucket: "simply-guitar-website.appspot.com",
  locationId: "us-central",
  messagingSenderId: "873734146319",
  appId: "1:873734146319:web:745dd97c6f314c5400167a",
  measurementId: "G-Q3ND78PN60",
}

const sessionId = getSessionId()

firebase.initializeApp(firebaseConfig)

export type AnalyticsLogger = (event: AnalyticsEvent) => void

const firebaseLogger = (event: AnalyticsEvent) => {
  firebase.analytics().logEvent(event.name, event.params)
}

const firestoreLogger = (event: AnalyticsEvent) => {
  const eventRecord = { ...event.params, name: event.name }
  firebase.firestore().collection('simply.web.analytics').add(eventRecord)
}

const combineLoggers = (...loggers: AnalyticsLogger[]): AnalyticsLogger => event =>
  forEach(logger => logger(event), loggers)

export const enrichEventParams = (logger: AnalyticsLogger): AnalyticsLogger => event => {
  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search))

  const defaultParams: Partial<AnalyticsEventParams> = {
    route: window.location.pathname,
    params: JSON.stringify(queryParams) || null,
    email: queryParams.email || null,
    accountId: queryParams.accountID || null,
    deviceId: getDeviceId(),
    session: sessionId,
    sessionId,
    timestamp: new Date().toISOString()
  }

  const enrichedEvent = Object.assign({}, event, { params: defaultParams })
  Object.assign(enrichedEvent.params, event.params)

  logger(enrichedEvent)
}

const consoleLogger = (event: AnalyticsEvent) => {
  const getLogSummary = () => {
    switch (event.name) {
      case 'error':
        return event.params.error
      default:
        return event.params.itemName
    }
  }

  console.groupCollapsed(`[${event.name}]: ${getLogSummary()}`)
  console.log(JSON.stringify(event.params, null, 2))
  console.groupEnd()
}

const loggers: Record<string, AnalyticsLogger> = {
  test: noop,
  development: flow(enrichEventParams(consoleLogger)),
  production: flow(enrichEventParams(combineLoggers(firebaseLogger, firestoreLogger, pinpointLogger)))
}

export const logAnalyticsEvent = loggers[process.env.NODE_ENV]

type AnalyticsEventName = firebase.analytics.CustomEventName<
  'page' | 'click' | 'api-call' | 'api-call-return' | 'business' | 'error' | 'view'
>

export interface AnalyticsEventParams {
  readonly [key: string]: string | null | undefined
}

export interface AnalyticsEvent {
  readonly name: AnalyticsEventName
  readonly params: AnalyticsEventParams
}

export type RestEventParams = Partial<AnalyticsEventParams>

export const clickEvent = (buttonName: string): AnalyticsEvent => ({
  name: 'click',
  params: {
    itemName: buttonName,
    itemType: 'button'
  }
})

export const viewEvent = (itemName: string, rest: RestEventParams = {}): AnalyticsEvent => ({
  name: 'view',
  params: {
    itemName,
    itemType: 'screen',
    ...rest
  }
})

export const apiCallEvent = (endpoint: string, callParams: Record<string, string> = {}): AnalyticsEvent => ({
  name: 'api-call',
  params: {
    itemName: endpoint,
    itemType: 'API',
    status: 'pending',
    details: JSON.stringify(callParams)
  }
})

export const apiCallReturnEvent = (endpoint: string, returnValue: string = ''): AnalyticsEvent => ({
  name: 'api-call-return',
  params: {
    itemName: endpoint,
    itemType: 'API',
    status: 'success',
    details: returnValue
  }
})

export const businessEvent = (eventName: string, eventParams: Record<string, string> = {}): AnalyticsEvent => ({
  name: 'business',
  params: {
    details: JSON.stringify(eventParams),
    itemName: eventName,
    itemType: 'API'
  }
})

export const errorEvent = (error: string, rest: RestEventParams = {}): AnalyticsEvent => ({
  name: 'error',
  params: { error, ...rest }
})
