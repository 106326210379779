import styled from 'styled-components'

export const SwitchContainer = styled.div`
  margin-left: 1em;
  margin-top: 1em;
  text-align: left;
`

export const Select = styled.select`
  font-size: 1rem;
  border: 0;
  cursor: pointer;
`
