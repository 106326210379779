import { ErrorId, ErrorView } from './ErrorView'
import React, { FC } from 'react'
import { useTranslation } from 'services/localization/TranslationProvider'
import { Portal } from 'react-portal'
import { Background } from './errorPageStyledComponents'
import { Glass } from 'ui/Glass'

interface ErrorDialogProps {
  errorId?: ErrorId
  onClose(): void
}

export const ErrorDialog: FC<ErrorDialogProps> = ({ errorId, onClose }) => {
  const translation = useTranslation().errors
  const buttonLabel = errorId === 'payment' ? translation.tryAgain : translation.goBack

  return errorId ? (
    <Portal>
      <Glass>
        <Background>
          <ErrorView errorId={errorId} onClose={onClose} buttonLabel={buttonLabel} />
        </Background>
      </Glass>
    </Portal>
  ) : null
}
