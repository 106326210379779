import React, { FC } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { TopPitch } from './TopPitch'
import { BottomPitch } from './BottomPitch'
import { PlanDetails } from '../../services/accounts/Accounts'
import { isDebugMode } from 'services/debug/Debug'
import { PaymentForm } from './PaymentForm'
import {PaymentGrid, PitchBackground} from './StyledComponents'

const getStripeApiKey = (): string => {
  const stripeTestApiKey = process.env.REACT_APP_STRIPE_TEST_API_KEY!
  const stripeLiveApiKey = process.env.REACT_APP_STRIPE_LIVE_API_KEY!

  if (isDebugMode()) {
    return stripeTestApiKey
  } else if (process.env.NODE_ENV === 'production') {
    return stripeLiveApiKey
  } else {
    return stripeTestApiKey
  }
}

const stripe = loadStripe(getStripeApiKey())

interface PurchaseScreenProps {
  email: string
  planDetails: PlanDetails
  discount: number
}

export const PurchaseScreen: FC<PurchaseScreenProps> = ({ email, planDetails, discount }) => {
  let pitchBackground = <PitchBackground />
  let form = (<PaymentForm email={email} planDetails={planDetails} discount={discount} />)
  let topPitch = <TopPitch duration={planDetails.duration} discount={discount * 100} />
  let bottomPitch = <BottomPitch />

  return (
    <Elements
      stripe={stripe}
      options={{
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap' }]
      }}
    >
      <PaymentGrid>
        {topPitch}
        {bottomPitch}
        {pitchBackground}
        {form}
      </PaymentGrid>
    </Elements>
  )
}
