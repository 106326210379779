import { Language } from './languages'

import guide from './strings.guide.json'

import de from './strings.de.json'
import en from './strings.en.json'
import es from './strings.es.json'
import fr from './strings.fr.json'
import it from './strings.it.json'
import ja from './strings.ja.json'
import ko from './strings.ko.json'
import ptBR from './strings.pt-BR.json'
import zhHans from './strings.zh-Hans.json'
import zhHant from './strings.zh-Hant.json'
export type Translation = typeof guide

export type Translations = Record<Language, Translation>

const translations: Translations = {
  de,
  en,
  es,
  fr,
  it,
  ja,
  ko,
  'pt-BR': ptBR,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant
}

export const getTranslation = (language: Language): Translation => translations[language]
