import { isArray } from 'lodash/fp'
import { compile } from 'handlebars'

const NO_CENT_CURRENCIES = ['jpy', 'krw']

export const asArray = <T>(x: T | T[]): T[] => (isArray(x) ? x : [x])

export const formatPrice = (priceInCents: number, currencyCode: string, locale: string): string =>
  addCurrency(priceInWholeUnits(priceInCents, currencyCode), currencyCode, locale)

export const formatSubtotal = (
  months: number,
  price: number,
  currency: string,
  locale: string,
  template: string
): string => {
  const perMonth = Math.floor(price / months)
  const formattedPrice = addCurrency(priceInWholeUnits(perMonth, currency), currency, locale)
  return compile(template)({ months, price: formattedPrice })
}

export const formatDiscountSaving = (percent: number, template: string): string =>
  compile(template)({ amount: percent })

const addCurrency = (price: number, currencyCode: string, locale: string): string => {
  let decimalDigits = 2
  if (NO_CENT_CURRENCIES.includes(currencyCode.toLowerCase())) {
    decimalDigits = 0
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: decimalDigits
  })
  return formatter.format(price)
}

const priceInWholeUnits = (priceInCents: number, currencyCode: string) => {
  if (NO_CENT_CURRENCIES.includes(currencyCode.toLowerCase())) {
    return priceInCents
  }
  return priceInCents / 100.0
}

export const formatTextWithEmail = (email: string, template: string) => compile(template)({ email })

const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(|\\)+)])/

export const isEmailValid = (email: string): boolean => emailPattern.test(email)

export type DeviceType = 'iOS' | 'Android' | 'Desktop'

export const getDeviceType = (): DeviceType => {
  if (
    /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
    (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints === 5)
  ) {
    return 'iOS'
  } else if (/Android/i.test(navigator.userAgent)) {
    return 'Android'
  } else {
    return 'Desktop'
  }
}
