import React from 'react'
import { AnalyticsEventParams } from 'services/analytics/analytics'
import { useLocation } from '@reach/router'
import { parse as parseQueryString } from 'query-string'
import { readUtmParams } from './util/readUtmParams'

export const useDefaultAnalyticsParams = () => {
  const location = useLocation()
  const details = getDetails(location)

  return React.useMemo((): AnalyticsEventParams => {
    const utmParams = readUtmParams()

    return {
      appVersion: '0.0.1',
      env: process.env.NODE_ENV,
      languages: window.navigator.languages.join(','),
      userAgent: window.navigator.userAgent,
      viewHeight: window.innerHeight.toString(),
      viewWidth: window.innerWidth.toString(),
      ...details,
      ...utmParams
    }
  }, [details])
}

const getDetails = (location: Location): AnalyticsEventParams => {
  const parsedQuery = parseQueryString(location.search)
  const referrerAccountId = (parsedQuery['af_referrer_customer_id'] as string) ?? ''

  return { referrerAccountId }
}
