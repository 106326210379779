import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useLocation } from '@reach/router'
import { parse as parseQueryString } from 'query-string'
import { PurchaseScreen } from './components/PurchaseScreen'
import { isUndefined, defaultTo, assignWith } from 'lodash/fp'
import { Loading } from 'ui/Loading'
import { isSuccess, Result } from 'services/results'
import { getErrorPath } from 'ui/errors/errorPage/ErrorView'
import { accounts, PlanDetails } from '../services/accounts/Accounts'
import { useLanguage } from 'services/localization/TranslationProvider'
import { AnalyticsProvider, useAnalytics } from 'services/analytics/AnalyticsProvider'
import { apiCallEvent, apiCallReturnEvent, errorEvent } from 'services/analytics/analytics'
import sgPlans from './sg-plans.json'


interface SubscriptionPlanGroup {
  id: string
  discount: number
}
interface SubscribeRequestParams {
  country: string
  email: string
  code: string
}
const DEFAULT_REQUEST_PARAM: SubscribeRequestParams = {
  country: 'United States',
  email: '',
  code: ''
}

export const Subscribe: FC = () => {
  const location = useLocation()
  const { country, email } = assignWith(defaultTo, DEFAULT_REQUEST_PARAM, parseQueryString(location.search)) ?? DEFAULT_REQUEST_PARAM

  const [language] = useLanguage()

  const [planDetails, setPlanDetails] = useState<PlanDetails>()
  const navigate = useNavigate()

  const logAnalyticsEvent = useAnalytics()

  let plans = sgPlans
  let planGroup: SubscriptionPlanGroup = { id: 'sg-discount', discount: 0.25 }

  useEffect(() => {
    const getUserCurrencyFromStripe = async (email: string) => {
      logAnalyticsEvent(apiCallEvent('accounts.getUserCurrencyFromStripe'))
      const result = await accounts.getUserCurrencyFromStripe(email)
      if (isSuccess(result)) {
        logAnalyticsEvent(apiCallReturnEvent('accounts.getUserCurrencyFromStripe', result.value))
        return result.value
      } else {
        logAnalyticsEvent(errorEvent('accounts.getUserCurrencyFromStripe'))
        await navigate(getErrorPath('general'))
      }
    }
    const fetchPlanDetails = async (
      currency: string,
      func: (param: string, plans: string[]) => Promise<Result<PlanDetails, string>>
    ) => {
      logAnalyticsEvent(apiCallEvent(func.name))
      const result = await func(currency, plans)
      if (isSuccess(result)) {
        logAnalyticsEvent(apiCallReturnEvent(func.name, result.value.id))
        setPlanDetails(result.value)
      } else {
        logAnalyticsEvent(errorEvent(func.name))
        await navigate(getErrorPath('general'))
      }
    }
    
    if (email) {
      getUserCurrencyFromStripe(email).then(currency => {
        if (currency != null) {
          fetchPlanDetails(currency, accounts.getPlanDetailsByCurrency)
        } else {
          fetchPlanDetails(country, accounts.getPlanDetails)
        }
      })
    } else {
      fetchPlanDetails(country, accounts.getPlanDetails)
    }
  }, [country, email, navigate, logAnalyticsEvent, plans])

  const hasPurchaseDetails = !isUndefined(planDetails)
  return (
    <AnalyticsProvider params={{ offerEmail: email, language, country, plan: planGroup.id }}>
      <Loading loading={!hasPurchaseDetails}>
        <PurchaseScreen
          email={email}
          planDetails={planDetails!}
          discount={planGroup.discount}
        />
      </Loading>
    </AnalyticsProvider>
  )
}
