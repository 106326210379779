import { useState, useRef } from 'react'

export const useLoading = (initialLoading: boolean = false): [boolean, () => void, () => void] => {
  const [loading, setLoading] = useState(initialLoading)
  const loadingRef = useRef(initialLoading)

  const start = () => {
    if (!loadingRef.current) {
      loadingRef.current = true
      setLoading(true)
    }
  }

  const stop = () => {
    if (loadingRef.current) {
      loadingRef.current = false
      setLoading(false)
    }
  }

  return [loading, start, stop]
}
