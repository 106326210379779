import { includes } from 'lodash/fp'

export const languages = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt-BR', 'zh-Hans', 'zh-Hant'] as const

export type Language = typeof languages[number]

export const languageNames: Record<Language, string> = {
  de: 'Deutsch',
  fr: 'français',
  en: 'English',
  'pt-BR': 'português',
  es: 'español',
  it: 'italiano',
  ja: '日本語',
  ko: '한국어',
  'zh-Hans': '简体中文',
  'zh-Hant': '繁體中文'
}

export const isLanguage = (language: unknown): language is Language => includes(language, languages)
