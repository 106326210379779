import beethoven from '../beethoven.png'
import lennon from '../john-lennon.png'
import { has, isUndefined } from 'lodash/fp'
import React, { FC } from 'react'
import { useTranslation } from 'services/localization/TranslationProvider'
import { Button, ButtonContainer, ErrorContainer, ImageContainer, Message, Title } from './errorPageStyledComponents'
import { Image } from 'ui/Image'

const errorImages = {
  pageNotFound: beethoven,
  general: beethoven,
  accountAlreadyExists: lennon,
  payment: beethoven
}

export type ErrorId = keyof typeof errorImages

export const getErrorPath = (id: ErrorId): string => `/error/${id}`

export const isErrorId = (errorId?: string): errorId is ErrorId => !isUndefined(errorId) && has(errorId, errorImages)

interface ErrorViewProps {
  errorId: ErrorId
  buttonLabel: string
  onClose(): void
}

export const ErrorView: FC<ErrorViewProps> = ({ errorId, onClose, buttonLabel }) => {
  const translation = useTranslation().errors
  const { title, message } = translation[errorId]
  const image = errorImages[errorId]

  return (
    <ErrorContainer>
      <ImageContainer>
        <Image width="200px" alt="Error" src={image} />
      </ImageContainer>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <ButtonContainer>
        <Button onClick={onClose}>{buttonLabel}</Button>
      </ButtonContainer>
    </ErrorContainer>
  )
}
