import React, { FC, useCallback, useRef, useState } from 'react'
import { useLocation, useNavigate } from '@reach/router'
import { once } from 'lodash/fp'
import {
  RockJamBackground,
  Container,
  LogoContainer,
  Logo,
  RockJamTitle,
  RockJamSubtitle,
  Input,
  InputContainer,
  FieldError,
  Button,
  ButtonContainer,
} from './components/StyledComponents'
import { Loading } from 'ui/Loading'
import { ErrorId } from 'ui/errors/errorPage/ErrorView'
import { ErrorDialog } from 'ui/errors/errorPage/ErrorDialog'
import { isEmailValid } from 'util/utils'
import { useLoading } from 'util/useLoading'
import { useTranslation } from 'services/localization/TranslationProvider'
import { accounts } from 'services/accounts/Accounts'
import { isSuccess } from 'services/results'
import { useAnalytics, useViewAnalyticsEvent } from 'services/analytics/AnalyticsProvider'
import { businessEvent, clickEvent, apiCallEvent, apiCallReturnEvent, errorEvent } from 'services/analytics/analytics'
import sgLogo from './images/sg-logo.png'

export const RockJam: FC = () => {
  useViewAnalyticsEvent('rockjamsg')

  const translation = useTranslation().rockjam
  const navigate = useNavigate()
  const location = useLocation()
  const logAnalyticsEvent = useAnalytics()
  const [email, setEmail] = useState('')
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [loading, startLoading, stopLoading] = useLoading()
  const [errorId, setErrorId] = useState<ErrorId>()
  const emailRef = useRef<HTMLInputElement>(null)

  // update and log emails entered
  const logEmailEvent = (
    status: 'change' | 'complete'
  ) => () => {
    logAnalyticsEvent(businessEvent('email', { status }))
  }
  const logEmailChange = useCallback(once(logEmailEvent('change')), [])
  const logEmailComplete = useCallback(once(logEmailEvent('complete')), [])

  const updateEmail = (email: string) => {
    setEmail(email)
    logEmailChange()
    if (isEmailValid(email)) {
      logEmailComplete()
    }
  }
 
  // validate and redeem email
  const reedem = async (email: string) => {
    startLoading()
    try {
      logAnalyticsEvent(apiCallEvent('accounts.reedemRockJamCoupon', { email }))
      const result = await accounts.reedemRockJamCoupon(email)
      if (isSuccess(result)) {
        stopLoading()
        logAnalyticsEvent(apiCallReturnEvent('accounts.reedemRockJamCoupon'))
        await navigate(`/couponapplied/${email}`, { state: { search: location.search } })
      } else {
        logAnalyticsEvent( 
          errorEvent(`Error in accounts.reedemRockJamCoupon ${result.error.errorCode}: ${result.error.errorString}`)
        )
        if (result.error.errorCode === 1) {
          setShowValidationErrors(true)
        } else {
          setErrorId('general')
        }
      }      
    } finally {
      stopLoading()
    }
  }
  const emailValid = isEmailValid(email)
  const tryReedem = () => {
    logAnalyticsEvent(clickEvent('reedem'))

    if (emailValid) {
      logAnalyticsEvent(businessEvent('reedem', { email }))
      reedem(email)
    } else {
      setShowValidationErrors(true)
    }
  }

  return (
    <RockJamBackground>
      <ErrorDialog errorId={errorId} onClose={() => setErrorId(undefined)} />
      <Loading loading={loading} darken={true} />
      <Container>

        <LogoContainer>
          <Logo alt="Simply Guitar" width='315px' src={sgLogo} />
        </LogoContainer>

        <RockJamTitle>{translation.title}</RockJamTitle>
        <RockJamSubtitle>{translation.subtitle}</RockJamSubtitle>
        <p/>

        <InputContainer error={showValidationErrors && !emailValid}>
          <Input
            data-testid="email-input"
            autoFocus
            ref={emailRef}
            type="email"
            value={email}
            onChange={e => updateEmail(e.target.value)}
            placeholder={translation.email.placeholder}
          />
        </InputContainer>
        {showValidationErrors && !emailValid && <FieldError>{translation.email.validationError}</FieldError>}
        {showValidationErrors && emailValid && <FieldError>{translation.couponError}</FieldError>}

        <ButtonContainer visible={true}>
          <Button onClick={tryReedem}>{translation.buttonLabel}</Button>
        </ButtonContainer>

        <p/>
      </Container>
    </RockJamBackground>
  )
}
