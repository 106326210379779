import React, { Component, ErrorInfo } from 'react'
import { ErrorPage } from './errors/errorPage/ErrorPage'
import { errorEvent, logAnalyticsEvent } from 'services/analytics/analytics'

export class ErrorBoundary extends Component {
  public state = {
    hasError: false
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logAnalyticsEvent(errorEvent(`${error.message}: ${errorInfo.componentStack}`))
  }

  render() {
    return this.state.hasError ? <ErrorPage /> : this.props.children
  }
}
