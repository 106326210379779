import styled from 'styled-components'

interface GlassProps {
  darken?: boolean
}

export const Glass = styled.div<GlassProps>`
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: ${({ darken }) => (darken === true ? '#88888888' : '#ffffff')};
`
