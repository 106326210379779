import { isEmpty, toPairs, map, join } from 'lodash/fp'
import { Accounts, PlanDetails, SubscriptionError, ReedemError } from './Accounts'
import { Failure, Result, Success } from 'services/results'
import { isDebugMode } from 'services/debug/Debug'

const ASLA_URL = `${process.env.REACT_APP_ASLA_URL}/server/asla`

interface QueryParameters {
  [key: string]: string | string[] | boolean
}
const buildQueryString = (params: QueryParameters): string =>
  isEmpty(params) ? '' : `?${join('&', map(join('='), toPairs(params)))}`
const buildUrl = (path: string, params: QueryParameters = {}): string => `${path}${buildQueryString(params)}`
const aslaEndpointPath = (name: string): string => `${ASLA_URL}/${name}`
const apiParams = (): QueryParameters => (isDebugMode() ? { isTest: true } : {})

export const _getPlanDetails = async (
  endPoint: string,
  key: string,
  value: string,
  plans: string[]
): Promise<Result<PlanDetails, string>> => {
  try {
    const response = await fetch(buildUrl(aslaEndpointPath(endPoint), apiParams()), {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ [key]: value, planIds: plans }),
      headers: { 'Content-Type': 'application/json' }
    })
    const planDetails = await response.json()
    if (planDetails.error) {
      return Failure(planDetails.error)
    }
    return Success(planDetails)
  } catch (e) {
    return Failure(`Error calling ${endPoint}`)
  }
}

export const getPlanDetails = async (country: string, plans: string[]): Promise<Result<PlanDetails, string>> => {
  return _getPlanDetails('accounts/stripeGetPricingPlanByCountry', 'country', country, plans)
}

export const getPlanDetailsByCurrency = async (
  currency: string,
  plans: string[]
): Promise<Result<PlanDetails, string>> => {
  return _getPlanDetails('accounts/stripeGetPricingPlanByCurrency', 'currency', currency, plans)
}

const createSubscription = async (
  paymentMethodId: string,
  email: string,
  plan: string,
  isTest: boolean = false
): Promise<Result<undefined, SubscriptionError>> => {
  try {
    const response = await fetch(buildUrl(aslaEndpointPath('accounts/accountApplyWebPurchase'), apiParams()), {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        paymentMethodId,
        email,
        plan,
        isTest
      }),
      headers: { 'Content-Type': 'application/json' }
    })

    if (!response.ok) {
      const defaultError = 'Unexpected error occurred'

      try {
        const result = await response.json()
        return Failure({ errorCode: response.status, errorString: result.error ?? defaultError })
      } catch (parseError) {
        return Failure({ errorCode: response.status, errorString: defaultError })
      }
    }
    return Success(undefined)
  } catch (e) {
    return Failure({ errorCode: 0, errorString: 'Error calling accounts/accountApplyWebPurchase' })
  }
}

const getUserCurrencyFromStripe = async (email: string): Promise<Result<string, string>> => {
  try {
    const url = buildUrl(aslaEndpointPath('accounts/getUserCurrencyFromStripe'), apiParams())
    console.log(url)
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        email
      }),
      headers: { 'Content-Type': 'application/json' }
    })

    const res = await response.json()
    return Success(res.currency)
  } catch (e) {
    console.log(e)
    return Failure('Error calling accounts/getUserCurrencyFromStripe')
  }
}

const reedemRockJamCoupon = async (email: string): Promise<Result<undefined, ReedemError>> => {
  const coupon = 'ROCKJAMSG2022'
  const locale = 'en'
  try {
    const url =
      '//www.joytunes.com/server/accounts/api/webActivateCouponForEmail.php?email=' +
      encodeURIComponent(email) +
      '&couponCode=' +
      coupon +
      '&locale=' +
      locale
    console.log(url)
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors'
    })

    const res = await response.json()
    if (res.error) {
      return Failure({ errorCode: 1, errorString: res.error })
    }
    console.log(res)
    return Success(undefined)
  } catch (e) {
    console.log(`${e}`)
    return Failure({ errorCode: 0, errorString: 'Error calling accounts/webActivateCouponForEmail' })
  }
}

const redeemTunerOfferCoupon = async (email: string): Promise<Result<undefined, ReedemError>> => {
  const coupon = 'TUNER1MONTHOFFER'
  const locale = 'en'
  try {
    const url =
      '//www.joytunes.com/server/accounts/api/webActivateCouponForEmail.php?email=' +
      encodeURIComponent(email) +
      '&couponCode=' +
      coupon +
      '&locale=' +
      locale
    console.log(url)
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors'
    })

    const res = await response.json()
    if (res.error) {
      return Failure({ errorCode: 1, errorString: res.error })
    }
    console.log(res)
    return Success(undefined)
  } catch (e) {
    console.log(`${e}`)
    return Failure({ errorCode: 0, errorString: 'Error calling accounts/webActivateCouponForEmail' })
  }
}

const redeemWomUniOfferCoupon = async (email: string, onemonth: boolean): Promise<Result<undefined, ReedemError>> => {
  let coupon = 'WOMUNI1YEAROFFER'

  if (onemonth) {
    coupon = 'WOMUNI1MONTHOFFER'
  }

  const locale = 'en'
  try {
    const url =
      '//www.joytunes.com/server/accounts/api/webActivateCouponForEmail.php?email=' +
      encodeURIComponent(email) +
      '&couponCode=' +
      coupon +
      '&locale=' +
      locale
    console.log(url)
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors'
    })

    const res = await response.json()
    if (res.error) {
      return Failure({ errorCode: 1, errorString: res.error })
    }
    console.log(res)
    return Success(undefined)
  } catch (e) {
    console.log(`${e}`)
    return Failure({ errorCode: 0, errorString: 'Error calling accounts/webActivateCouponForEmail' })
  }
}

const reedemVipGiftCoupon = async (email: string): Promise<Result<undefined, ReedemError>> => {
  const coupon = 'VIPGIFT'
  const locale = 'en'
  try {
    const url =
      '//www.joytunes.com/server/accounts/api/webActivateCouponForEmail.php?email=' +
      encodeURIComponent(email) +
      '&couponCode=' +
      coupon +
      '&locale=' +
      locale
    console.log(url)
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors'
    })

    const res = await response.json()
    if (res.error) {
      return Failure({ errorCode: 1, errorString: res.error })
    }
    console.log(res)
    return Success(undefined)
  } catch (e) {
    console.log(`${e}`)
    return Failure({ errorCode: 0, errorString: 'Error calling accounts/webActivateCouponForEmail' })
  }
}

export const accounts: Accounts = {
  getPlanDetails,
  getPlanDetailsByCurrency,
  createSubscription,
  getUserCurrencyFromStripe,
  reedemRockJamCoupon,
  redeemTunerOfferCoupon,
  reedemVipGiftCoupon,
  redeemWomUniOfferCoupon
}
