import { getDeviceId } from 'util/getDeviceId'
import { ReferralQueryParams } from 'util/referralQueryParams'

type QueryStringParams = { [key: string]: string }

const AF_DOWNLOAD_URL = 'https://joytunes.onelink.me/fgQf/7f3a6d66'

const DEFAULT_PARAMS: QueryStringParams = {
  pid: 'web_purchase',
  af_web_dp: 'https://apps.apple.com/us/app/simply-guitar-by-joytunes/id1476695335',
  af_dp: 'jtsimplyguitar://',
  ad_id: getDeviceId(),
  af_channel: 'sg_inapp'
}

const PLAYSTORE_PARAMS: QueryStringParams = {
  pid: 'web_purchase',
  af_web_dp: 'https://play.google.com/store/apps/details?id=com.joytunes.simplyguitar',
  af_dp: 'jtsimplyguitar://',
  ad_id: getDeviceId(),
  af_channel: 'sg_inapp'
}

// https://play.google.com/store/apps/details?id=com.joytunes.simplyguitar

export function appFlyerDownloadLink(referralQueryParams?: ReferralQueryParams) {
  const queryParams = new URLSearchParams(Object.assign({}, DEFAULT_PARAMS, referralQueryParams))
  return `${AF_DOWNLOAD_URL}?${queryParams.toString()}`
}

export function appFlyerDownloadLinkPlayStore(referralQueryParams?: ReferralQueryParams) {
  const queryParams = new URLSearchParams(Object.assign({}, PLAYSTORE_PARAMS, referralQueryParams))
  return `${AF_DOWNLOAD_URL}?${queryParams.toString()}`
}
