import React, { FC } from 'react'
import { useTranslation } from 'services/localization/TranslationProvider'
import { ErrorView, isErrorId } from './ErrorView'

interface ErrorPageParams {
  id?: string
}

export const ErrorPage: FC<ErrorPageParams> = ({ id }) => {
  const errorId = isErrorId(id) ? id : 'general'
  const translation = useTranslation().errors

  const goBack = () => {
    window.history.back()
  }

  return <ErrorView errorId={errorId} onClose={goBack} buttonLabel={translation.goBack} />
}
