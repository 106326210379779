import React, { FC, createContext, useContext, useEffect } from 'react'
import {
  AnalyticsEventParams,
  AnalyticsEvent,
  logAnalyticsEvent,
  viewEvent,
  AnalyticsLogger,
  RestEventParams
} from './analytics'
import { useDefaultAnalyticsParams } from '../../useAppDefaultAnalyticsParams'

const AnalyticsContext = createContext<AnalyticsEventParams>({})

interface AnalyticsProviderProps {
  params: AnalyticsEventParams
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ params, children }) => (
  <AnalyticsContext.Provider value={{ ...useDefaultAnalyticsParams(), ...useContext(AnalyticsContext), ...params }}>
    {children}
  </AnalyticsContext.Provider>
)

export const useAnalytics = (): AnalyticsLogger => {
  const analyticsContext = useContext(AnalyticsContext)

  return React.useCallback(
    (event: AnalyticsEvent) => {
      const paramsWithContext = Object.assign({}, analyticsContext, event.params)
      logAnalyticsEvent({ name: event.name, params: paramsWithContext })
    },
    [analyticsContext]
  )
}

export const useViewAnalyticsEvent = (pageName: string, rest: RestEventParams = {}): void => {
  const analytics = useAnalytics()
  useEffect(() => {
    analytics(viewEvent(pageName, rest))
  }, [analytics, pageName, rest])
}
