import React, { FC, useState } from 'react'
import styled from 'styled-components'
import CopyToClipboard from 'react-copy-to-clipboard'
import { map, toPairs } from 'lodash/fp'

const Dialog = styled.div`
  position: fixed;
  z-index: 100;
  padding: 8px;
  background-color: #f17de5;
  border: 3px solid #7243e5;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  overflow: auto;
`

const Text = styled.div`
  font-size: 1.5em;
  font-family: monospace;
  font-weight: 400;
  color: #472e44;
`

const Button = styled.button`
  font-size: 1.5em;
  font-family: monospace;
  font-weight: 400;
  color: purple;
  border: 3px solid #7243e5;
  background-color: #f6baef;
  margin: 2px;
  cursor: pointer;
`

export const DebugInfo: FC = () => {
  const [visible, setVisible] = useState(true)
  const [copied, setCopied] = useState(false)

  const getWURFLKey = (key: keyof WURFL): string | boolean | number | undefined => window.WURFL?.[key]

  const debugInfo = {
    Version: process.env.REACT_APP_VERSION,
    'User agent': navigator.userAgent,
    'Max touch points': navigator.maxTouchPoints,
    Platform: navigator.platform,
    ontouchend: document.ontouchend,
    'Device OS': getWURFLKey('advertised_device_os'),
    'OS version': getWURFLKey('advertised_device_os_version'),
    Brand: getWURFLKey('brand_name'),
    'Device name': getWURFLKey('complete_device_name'),
    'Form Factor': getWURFLKey('form_factor'),
    'Is desktop': getWURFLKey('is_full_desktop'),
    'Is mobile': getWURFLKey('is_mobile'),
    'Is smartphone': getWURFLKey('is_smartphone'),
    'Is tablet': getWURFLKey('is_tablet'),
    'Model name': getWURFLKey('model_name'),
    'Pointing method': getWURFLKey('pointing_method')
  }

  return visible ? (
    <Dialog>
      <CopyToClipboard text={JSON.stringify(debugInfo)} onCopy={() => setCopied(true)}>
        <Button>Copy to clipboard</Button>
      </CopyToClipboard>
      <Button onClick={() => setVisible(false)}>Close</Button>
      {copied && <Text>Copied</Text>}

      {map(
        ([key, value]) => (
          <Text key={key}>
            {key}: {value}
          </Text>
        ),
        toPairs(debugInfo)
      )}
    </Dialog>
  ) : null
}
