import { useRef } from 'react'

export const useTimer = (millis: number, fn: () => void): [() => void, () => void] => {
  const tooltipTimer = useRef<number>()

  const start = () => {
    tooltipTimer.current = setTimeout(() => {
      cancel()
      fn()
    }, millis)
  }

  const cancel = () => {
    tooltipTimer.current && clearTimeout(tooltipTimer.current)
  }

  return [start, cancel]
}
